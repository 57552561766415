import { cn } from "@/lib/utils";
import {
  ComponentPropsWithRef,
  ElementType,
  forwardRef,
  HTMLProps,
} from "react";

export const Section = forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement>>(
  (props, ref) => {
    const { className = "", ...restProps } = props;

    return (
      <div ref={ref} className={cn("", props?.className)} {...restProps}>
        {props.children}
      </div>
    );
  },
);

type SectionBodyBaseProps<C> = {
  as?: C;
};

export type SectionBodyProps<C extends ElementType> = {
  as?: C;
} & Omit<ComponentPropsWithRef<C>, keyof SectionBodyBaseProps<C>>;

export const SectionBody = <C extends ElementType = "div">(
  props: SectionBodyProps<C>,
) => {
  const { className = "", as, ...restProps } = props;

  const Comp = as ? as : "div";

  return (
    <div className="px-4 sm:px-6">
      <Comp
        className={cn("mx-auto max-w-[1152px] border-x", className)}
        {...restProps}
      >
        {props.children}
      </Comp>
    </div>
  );
};
