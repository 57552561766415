import { InfoFill, Search as SearchIcon, Spinner } from "@jengaicons/react";
import { Input } from "@primitives/input";
import { Kbd } from "@primitives/kbd";
import cn from "clsx";
import NextLink from "next/link";
import { useRouter } from "next/navigation";
import {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
  type CompositionEvent,
  type KeyboardEvent,
  type ReactElement,
} from "react";

import { useMounted } from "../../../../../lib/hooks/use-mounted";
import type { SearchResult } from "./flexsearch";

type SearchProps = {
  className?: string;
  overlayClassName?: string;
  value: string;
  onChange: (newValue: string) => void;
  onActive?: (active: boolean) => void;
  loading?: boolean;
  error?: boolean;
  results: SearchResult[];
  align?: "start" | "end";
};

const INPUTS = ["input", "select", "button", "textarea"];

export function Search({
  overlayClassName,
  value,
  onChange,
  onActive,
  loading,
  error,
  results,
  align,
}: SearchProps): ReactElement {
  const [show, setShow] = useState(false);
  const [active, setActive] = useState(0);
  const router = useRouter();
  const input = useRef<HTMLInputElement>(null);
  const ulRef = useRef<HTMLUListElement>(null);
  const [focused, setFocused] = useState(false);
  const [composition, setComposition] = useState(true);

  useEffect(() => {
    setActive(0);
  }, [value]);

  useEffect(() => {
    const down = (e: globalThis.KeyboardEvent): void => {
      const activeElement = document.activeElement as HTMLElement;
      const tagName = activeElement?.tagName.toLowerCase();
      if (
        !input.current ||
        !tagName ||
        INPUTS.includes(tagName) ||
        activeElement?.isContentEditable
      )
        return;
      if (
        e.key === "/" ||
        (e.key === "k" &&
          (e.metaKey /* for Mac */ || /* for non-Mac */ e.ctrlKey))
      ) {
        e.preventDefault();
        // prevent to scroll to top
        input.current.focus({ preventScroll: true });
      } else if (e.key === "Escape") {
        setShow(false);
        input.current.blur();
      }
    };

    window.addEventListener("keydown", down);
    return () => {
      window.removeEventListener("keydown", down);
    };
  }, []);

  const finishSearch = useCallback(() => {
    input.current?.blur();
    onChange("");
    setShow(false);
  }, [onChange]);

  const handleActive = useCallback(
    (e: { currentTarget: { dataset: DOMStringMap } }) => {
      const { index } = e.currentTarget.dataset;
      setActive(Number(index));
    },
    [],
  );

  const handleKeyDown = useCallback(
    function <T>(e: KeyboardEvent<T>) {
      switch (e.key) {
        case "ArrowDown": {
          if (active + 1 < results.length) {
            const el = ulRef.current?.querySelector<HTMLAnchorElement>(
              `li:nth-of-type(${active + 2}) > a`,
            );
            if (el) {
              e.preventDefault();
              handleActive({ currentTarget: el });
              el.focus();
            }
          }
          break;
        }
        case "ArrowUp": {
          if (active - 1 >= 0) {
            const el = ulRef.current?.querySelector<HTMLAnchorElement>(
              `li:nth-of-type(${active}) > a`,
            );
            if (el) {
              e.preventDefault();
              handleActive({ currentTarget: el });
              el.focus();
            }
          }
          break;
        }
        case "Enter": {
          const result = results[active];
          if (result && composition) {
            void router.push(result.route);
            finishSearch();
          }
          break;
        }
        case "Escape": {
          setShow(false);
          input.current?.blur();
          break;
        }
      }
    },
    [active, results, router, finishSearch, handleActive, composition],
  );

  const mounted = useMounted();
  const renderList = show && Boolean(value);

  const icon = (
    <Kbd
      className={cn(
        "bg-subdued dark:bg-subdued h-5 rounded border px-1.5 font-mono text-[10px] font-medium text-gray-500",
        "items-center gap-1 transition-opacity",
        value
          ? "z-20 flex cursor-pointer hover:opacity-70"
          : "pointer-events-none hidden sm:flex",
      )}
    >
      {value && focused
        ? "ESC"
        : mounted &&
          (navigator.userAgent.includes("Macintosh") ? (
            <>
              <span className="text-xs">⌘</span>K
            </>
          ) : (
            "CTRL K"
          ))}
    </Kbd>
  );

  const handleComposition = useCallback(
    (e: CompositionEvent<HTMLInputElement>) => {
      setComposition(e.type === "compositionend");
    },
    [],
  );

  return (
    <div className={cn("relative w-full backdrop-blur-lg")}>
      <Input
        ref={input}
        value={value}
        className="md:w-96"
        onChange={(e) => {
          onChange(e.target.value);
          setShow(Boolean(e.target.value));
        }}
        onFocus={() => {
          onActive?.(true);
          setFocused(true);
        }}
        onBlur={() => {
          setFocused(false);
        }}
        onCompositionStart={handleComposition}
        onCompositionEnd={handleComposition}
        placeholder={"Search"}
        onKeyDown={handleKeyDown}
        suffix={icon}
        prefix={<SearchIcon className="text-soft" />}
      />

      {renderList && (
        <ul
          className={cn(
            "bg-default absolute top-full z-20 mt-2 rounded-lg border py-2.5 shadow-xl",
            "text-soft max-h-[400px] max-w-[600px] overflow-y-scroll",
            align == "start" ? "left-0" : "right-0",
            overlayClassName,
          )}
          ref={ulRef}
        >
          {error ? (
            <span className="flex select-none justify-center gap-2 p-8 text-center text-sm text-red-500">
              <InfoFill className="h-5 w-5" /> An error occured
            </span>
          ) : loading ? (
            <span className="flex select-none justify-center gap-2 p-8 text-center text-sm text-gray-400">
              <Spinner className="h-5 w-5 animate-spin" /> Loading...
            </span>
          ) : results.length > 0 ? (
            results.map(({ route, prefix, children, id }, i) => (
              <Fragment key={id}>
                {prefix}
                <li
                  className={cn(
                    "mx-2.5 break-words rounded-lg focus:ring-0",
                    "contrast-more:border",
                    i === active
                      ? "bg-active text-default border"
                      : "text-soft",
                  )}
                >
                  <NextLink
                    className="block scroll-m-12 px-2.5 py-2"
                    href={route}
                    data-index={i}
                    onFocus={handleActive}
                    onMouseMove={handleActive}
                    onClick={finishSearch}
                    onKeyDown={handleKeyDown}
                  >
                    {children}
                  </NextLink>
                </li>
              </Fragment>
            ))
          ) : (
            <span className="text-soft block select-none p-8 py-16 text-center text-sm">
              No results found.
            </span>
          )}
        </ul>
      )}
    </div>
  );
}
