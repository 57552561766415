import { cn } from "@/lib/utils";
import { cva, VariantProps } from "class-variance-authority";
import React, { ComponentPropsWithRef, forwardRef, ReactNode } from "react";

import { Text } from "./text";

export const inputVariants = cva(
  "text-bodyMd bg-input h-9 rounded-lg border focus-within:ring-focus px-3 focus-within:ring-2 [&_svg]:h-4 [&_svg]:w-4",
  {
    variants: {
      theme: {
        default: "",
        critical:
          "bg-critical-subdued border-critical disabled:border-default disabled:text-disabled disabled:bg-disabled",
        disabled: `bg-disabled text-soft border-default`,
      },
    },
    defaultVariants: {
      theme: "default",
    },
  },
);

interface InputBaseProps {
  prefix?: ReactNode;
  suffix?: ReactNode;
  errorMessage?: string;
  containerStyles?: string;
  disabled?: boolean;
  inputStyles?: string;
  errorStyles?: string;
}

interface InputProps
  extends InputBaseProps,
    VariantProps<typeof inputVariants>,
    Omit<ComponentPropsWithRef<"input">, keyof InputBaseProps> {}

const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    suffix,
    containerStyles,
    theme,
    errorMessage,
    className,
    inputStyles,
    prefix,
    errorStyles,
    ...restProps
  } = props;
  return (
    <div className={cn("relative w-full", containerStyles)}>
      <div
        className={cn(
          "flex items-center gap-3",
          inputVariants({ theme }),
          className,
        )}
      >
        {prefix && prefix}
        <input
          className={cn(
            "disabled:text-soft placeholder:text-disabled h-full min-h-8 w-full bg-[inherit] focus-within:outline-none hover:bg-[inherit] focus:outline-0",
            inputStyles,
          )}
          ref={ref}
          {...restProps}
        />
        {suffix && suffix}
      </div>
      {errorMessage?.trim() && (
        <ErrorMessage
          className={cn(`absolute mt-1`, errorStyles)}
          errorMessage={errorMessage}
        />
      )}
    </div>
  );
});

function ErrorMessage(props: { errorMessage: string; className?: string }) {
  return (
    <Text variant="bodySm" color="critical" className={props?.className || ""}>
      {props.errorMessage}
    </Text>
  );
}

export { Input, ErrorMessage };
