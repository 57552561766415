"use client";

import blogData from "@/content/blog/blog-data.json";
import docsData from "@/content/docs/docs-data.json";
import cn from "clsx";
import FlexSearch from "flexsearch";
import {
  useCallback,
  useState,
  type ReactElement,
  type ReactNode,
} from "react";

import { HighlightMatches } from "./highlight-matches";
import { Search } from "./search";

export type StructurizedData = Record<string, string>;

export type SearchData = {
  [route: string]: {
    title: string;
    data: StructurizedData;
  };
};

export type SearchResult = {
  children: ReactNode;
  id: string;
  prefix?: ReactNode;
  route: string;
};

type SectionIndex = FlexSearch.Document<
  {
    id: string;
    url: string;
    title: string;
    pageId: string;
    content: string;
    display?: string;
  },
  ["title", "content", "url", "display"]
>;

type PageIndex = FlexSearch.Document<
  {
    id: number;
    title: string;
    content: string;
  },
  ["title"]
>;

type Result = {
  _page_rk: number;
  _section_rk: number;
  route: string;
  prefix: ReactNode;
  children: ReactNode;
};

// This can be global for better caching.
const indexes: {
  [locale: string]: [PageIndex, SectionIndex];
} = {};

// Caches promises that load the index
const loadIndexesPromises = new Map<string, Promise<void>>();
const loadIndexes = (
  basePath: string,
  locale: string,
  contentType: "blog" | "docs",
): Promise<void> => {
  const key = basePath + "@" + locale;
  if (loadIndexesPromises.has(key)) {
    return loadIndexesPromises.get(key)!;
  }
  const promise = loadIndexesImpl({
    contentType,
    locale,
  });
  loadIndexesPromises.set(key, promise);
  return promise;
};

const loadIndexesImpl = async (props: {
  contentType: "blog" | "docs";
  locale: string;
}): Promise<void> => {
  const searchData =
    props.contentType === "blog" ? blogData : (docsData as SearchData);

  const pageIndex: PageIndex = new FlexSearch.Document({
    cache: 100,
    tokenize: "full",
    document: {
      id: "id",
      index: "content",
      store: ["title"],
    },
    context: {
      resolution: 9,
      depth: 2,
      bidirectional: true,
    },
  });

  const sectionIndex: SectionIndex = new FlexSearch.Document({
    cache: 100,
    tokenize: "full",
    document: {
      id: "id",
      index: "content",
      tag: "pageId",
      store: ["title", "content", "url", "display"],
    },
    context: {
      resolution: 9,
      depth: 2,
      bidirectional: true,
    },
  });

  let pageId = 0;

  for (const [route, structurizedData] of Object.entries(searchData)) {
    let pageContent = "";
    ++pageId;

    for (const [key, content] of Object.entries(structurizedData.data)) {
      const [headingId, headingValue] = key.split("#");
      const url = route + (headingId ? "#" + headingId : "");
      const title = headingValue || structurizedData.title;
      const paragraphs = content.split("\n");

      sectionIndex.add({
        id: url,
        url,
        title,
        pageId: `page_${pageId}`,
        content: title,
        ...(paragraphs[0] && { display: paragraphs[0] }),
      });

      for (let i = 0; i < paragraphs.length; i++) {
        sectionIndex.add({
          id: `${url}_${i}`,
          url,
          title,
          pageId: `page_${pageId}`,
          content: paragraphs[i],
        });
      }

      // Add the page itself.
      pageContent += ` ${title} ${content}`;
    }

    pageIndex.add({
      id: pageId,
      title: structurizedData.title,
      content: pageContent,
    });
  }

  indexes[props.locale] = [pageIndex, sectionIndex];
};

export function Flexsearch(props: {
  contentType: "blog" | "docs";
}): ReactElement {
  const basePath = "/";
  const locale = "en-US";

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [results, setResults] = useState<SearchResult[]>([]);
  const [search, setSearch] = useState("");

  const doSearch = (search: string) => {
    if (!search) return;
    const [pageIndex, sectionIndex] = indexes[locale];

    // Show the results for the top 5 pages
    const pageResults =
      pageIndex.search<true>(search, 5, {
        enrich: true,
        suggest: true,
      })[0]?.result || [];

    const results: Result[] = [];
    const pageTitleMatches: Record<number, number> = {};

    for (let i = 0; i < pageResults.length; i++) {
      const result = pageResults[i];
      pageTitleMatches[i] = 0;

      // Show the top 5 results for each page
      const sectionResults =
        sectionIndex.search<true>(search, 5, {
          enrich: true,
          suggest: true,
          tag: `page_${result.id}`,
        })[0]?.result || [];

      let isFirstItemOfPage = true;
      const occurred: Record<string, boolean> = {};

      for (let j = 0; j < sectionResults.length; j++) {
        const { doc } = sectionResults[j];
        const isMatchingTitle = doc.display !== undefined;
        if (isMatchingTitle) {
          pageTitleMatches[i]++;
        }
        const { url, title } = doc;
        const content = doc.display || doc.content;
        if (occurred[url + "@" + content]) continue;
        occurred[url + "@" + content] = true;
        results.push({
          _page_rk: i,
          _section_rk: j,
          route: url,
          prefix: isFirstItemOfPage && (
            <div
              className={cn(
                "border-default dark:text-soft mx-2.5 mb-2 mt-6 line-clamp-3 select-none border-b px-2.5 pb-1.5 text-xs font-semibold uppercase text-gray-500 first:mt-0",
                "contrast-more:border-gray-600 contrast-more:text-gray-900 contrast-more:dark:border-gray-50 contrast-more:dark:text-gray-50",
              )}
            >
              {result.doc.title}
            </div>
          ),
          children: (
            <>
              <div className="text-base font-semibold leading-5">
                <HighlightMatches match={search} value={title} />
              </div>
              {content && (
                <div className="mt-1 line-clamp-3 overflow-hidden text-ellipsis text-sm leading-[1.35rem]">
                  <HighlightMatches match={search} value={content} />
                </div>
              )}
            </>
          ),
        });
        isFirstItemOfPage = false;
      }
    }

    setResults(
      results
        .sort((a, b) => {
          // Sort by number of matches in the title.
          if (a._page_rk === b._page_rk) {
            return a._section_rk - b._section_rk;
          }
          if (pageTitleMatches[a._page_rk] !== pageTitleMatches[b._page_rk]) {
            return pageTitleMatches[b._page_rk] - pageTitleMatches[a._page_rk];
          }
          return a._page_rk - b._page_rk;
        })
        .map((res) => ({
          id: `${res._page_rk}_${res._section_rk}`,
          route: res.route,
          prefix: res.prefix,
          children: res.children,
        })),
    );
  };

  const preload = useCallback(
    async (active: boolean) => {
      if (active && !indexes[locale]) {
        setLoading(true);
        try {
          await loadIndexes(basePath, locale, props.contentType);
        } catch (e) {
          setError(true);
        }
        setLoading(false);
      }
    },
    [locale, basePath, props.contentType],
  );

  const handleChange = async (value: string) => {
    setSearch(value);
    if (loading) {
      return;
    }
    if (!indexes[locale]) {
      setLoading(true);
      try {
        await loadIndexes(basePath, locale, props.contentType);
      } catch (e) {
        setError(true);
      }
      setLoading(false);
    }
    doSearch(value);
  };

  return (
    <Search
      loading={false}
      error={error}
      value={search}
      onChange={handleChange}
      onActive={preload}
      results={results}
      overlayClassName="w-screen min-h-[100px] max-w-[min(calc(100vw-2rem),calc(100%+20rem))]"
      align={props.contentType === "blog" ? "end" : "start"}
    />
  );
}
